/**
 *  COPYRIGHT © 2022 MANU BHAT
 *  
 *  ALL RIGHTS RESERVED
 * 
 */

.body-theme-light {
  --bg: #FFF;
  --bg-alpha: rgba(238, 238, 238, 0.85);
  --ui: hsla(218, 100%, 13%, 0.7);
  --ui-baked: #485774;
  --ui1: #CCC;
  --accent: #001840;
  --accent1: #4abcf1;
  --accent2: #aae1ff;
  --accent3: #6795aa;
  --accent3rgb: 103, 149, 170;
  --complement: #FF0;
  --link: #40c8f5;

  --status-loading: rgb(85, 184, 255);
  --status-ok: #7f7;
  --status-err: #f77;

  --text: #000;

  --bold: 'Montserrat-bold', sans-serif;
  --light: 'Montserrat-light', sans-serif;
  --serif: 'PT serif', serif;
  --caps: 'Alegreya', sans-serif;
  --comp-prog: Verdana, Geneva, Tahoma, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0;
  background: var(--bg);
}

@font-face {
  font-family: 'Montserrat-bold';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat-light';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ptserif/v17/EJRVQgYoZZY2vCFuvAFWzr-_dSb_.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v20/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYiFV0UzdYPFkZVO.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/alegreyasanssc/v20/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1DipV9QREH4h2Mw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


section {
  position: relative;
}

.anchor-translator {
  position: absolute;
  top: -100px;

  width: 0px;
  height: 0px;
}

.window-background {
  background-color: rgba(20, 20, 20, 0.6);
  backdrop-filter: blur(5px) contrast(0.8) brightness(0.9) hue-rotate(05deg);
  border: solid 0.75px #444;
  border-radius: 7px;
  box-shadow: 0px 0px 1px 0.2px #000000;
}

main > h1 {
  font-family: var(--bold);
  font-size: 60px;
  color: #333;
  margin: 0px auto;
  text-align: center;
}