/* Overall wrapper */
#auth {
    min-height: 90vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* Esoteric Logo */
#auth-esoteric-logo {
    display: flex;

    margin-bottom: 10px;

    justify-content: center;
    align-items: center;
}
#auth-esoteric-logo a {
    font-family: var(--serif);
    text-decoration: none;
    color: var(--accent);

    font-size: 50px;
}

#auth-esoteric-logo img {
    height: 100px;
    width: 100px;
}

/* Background  */
#auth-aligner {
    display: flex;
    justify-content: center;
    align-items: center;
}
#auth-background {
    margin: 10px;
    padding: 15px;

    width: calc(min(100vw - 20px, 350px));
    box-sizing: border-box;
    background-color: var(--ui-baked) !important;

    box-shadow: 0px 0px 10px -2px black;
}

.auth-titled-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

/* Individual sub tasks */
#auth-subtitle {
    font-family: var(--light);
    font-size: 16px;
    color: #FFF;
    text-align: center;

    margin: 5px 0px;
}

#auth-forgot-password-helper {
    text-align: right;
    width: auto;
    padding-top: 0px;
    padding-right: 10px;
    margin: 0px 0px 3px;

    font-family: var(--light);
    font-size: 13px;
    color: var(--ui1);
}
#auth-forgot-password-helper span {
    font-family: var(--bold);
    color: var(--link);
    cursor: pointer;
}

/* Field separator */
#auth-third-party {
    margin: 10px 0px 0px;
}

.auth-separator {
    color: var(--accent3);
    font-family: var(--bold);

    display: flex;
    align-items: center;
}

.auth-separator span {
    flex-grow: 1;
    border: 1px solid var(--accent3);
    height: 0px;
    margin: 7px 10px 7px 10px;
}
/* third party apis */
.auth-third-party-api {
    text-align: center;
    font-family: var(--bold);
    
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    color: #FFF;
}

.auth-third-party-api p {
    display: inline;
}

.auth-third-party-api img {
    display: inline;
    margin-right: 5px;

    background-color: #FFF;
    padding: 3px;
    border-radius: 1000px;
}

#auth-switch-mode {
    font-family: var(--light);
    color: var(--ui1);
    font-size: 13px;
    text-align: center;
}

/* Opacity handle */
#auth-opacity-handle {
    transition: 0.2s ease-in;
    transition-property: all;
}

.auth-clear-handle {
    opacity: 0;
    transform: scale(0.975);

    transition-timing-function: ease-out;
}

#auth-terms {
    font-family: var(--light);
    font-size: 13px;
}

#auth-terms button {
    font-family: var(--light) !important;
    text-decoration: underline;   
}